import Vue from 'vue'
import Vuex from 'vuex'
import createWebSocketPlugin from '@/plugins/socketPlugin'
import spots from './modules/spots'
import socket from '../socket'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createWebSocketPlugin(socket)],
  state: {
    port:process.env.VUE_APP_PORT,
    tabSelected:0,
    path:"",
    users:[],
    connectedToTechnician: false,
    dialogSocketConnection: false,
    connections:{},
    allCitations:[],
    actualSpot:null,
    actualActivity: null,
    activityId:null,
    activityName:'',
    role: process.env.VUE_APP_ROLE,
    allowedCitations: false,
    textSpotSpeaker: '',
    dataSaveSpot: {},
    roleChangingTxt:'',
    sliderSocket: 24,
    closeShowRecording: false,
    studios:3,
    studio: null,
    arrStudios:[],
    recordings:[],
    arrSpots:[],
    arrSpeakersGroup:[],
    idStudio: null,
    auxDateCitations: null,
    mediaId: null,
    txtError: '',
    interval: null,
    textSuccess: '',
    currentCitation: null,
    currentSpeakerGroup: null,
    listSpots: [],
    listActivities: [],
    updateSpotList: false,
    tokenDefault:process.env.VUE_APP_TOKEN,
    token:'',
    refreshToken:``,
    tokenExpiration:``,
    refreshTokenExpiration:``,
    logued: false,
    requestSlaveRemote: false,
    currentSlaveRemote: null,
    accessRemote: null,
    currentUser: null,
    lostConnection: false,
  },
  getters: {
    showConnections(state){
      let objUsers={};
      state.users.forEach(user => {
        if (!objUsers[user.role]) objUsers[user.role] = 0
        objUsers[user.role] ++;
      });
      return objUsers;
    }
  },
  mutations: {
    setError(state,err){
      state.txtError = err
    },
    setSuccess(state,msg){
      state.textSuccess = msg
    },
    setIntervarl(state, interval){
      if (state.interval) {
        clearInterval(state.interval)
      }
      state.interval = interval
    },
    addConnectedUser(state, user){
      state.users.push(user)
    },
    emptyUsers(state){
      state.users.length = 0;
    },

    setConnectedToTechnician(state){
      state.connectedToTechnician = true;
    },
    setDialogSocketConnection(state, data){
      state.connectedToTechnician = false;
      state.dialogSocketConnection = data
    },
    setAuxDateCitations(state, date) {
      state.auxDateCitations = date
    },

    async setTypeSlave(state, type){
      if (type === 'plus') {
        state.role += '_plus';
        return state.role
      }
      if(type === 'plus_remote') {
        state.role += '_plus_remote'
        return state.role
      }
      return
    },

    allUsersConnected(state, data){
      if(state.actualSpot != null) {
        socket.emit('getAllowCitation', {toNamespace:"ECI_ESTUDIO", toRoom:`estudio${state.studio}`, data: { acces: true, recording: state.actualSpot }});
      }
      console.log(data)
      state.users = data
    },

    setCreatives(state, data){
      state.creatives = [...data]
    },
    setRecordings(state, recordings){
      state.recordings = recordings
    },
    setSpeakersGroup(state, data){
      state.arrSpeakersGroup = []
      data.forEach(task => {
        const group = task.speakerGroups
        const technicians = task.technicians
        const creatives = task.creatives
        group.forEach(sp => {
          if(!state.arrSpeakersGroup.some(gr => gr._id === sp._id)) state.arrSpeakersGroup.push( sp );
        })

      });
    },
    setSpots(state, spots){
      state.arrSpots = spots
    },
    setCitations(state, data){
    
      state.allCitations = data
      state.allCitations.forEach(citation => {
        const {_id:taskId, mediaId } = citation

        citation.activities = citation.activities.map(activitie => {
         return {...activitie, taskId}
        })
      })
    },
    setActiviyId(state, id){
      state.activiyId = id
    },
    setActiviyName(state, name){
      state.activiyName = name
    },
    setAccesCitations(state, value){
      state.allowedCitations = value
      if(value == true) {
        state.lostConnection = false
      }
    },
    setLostConnection(state) {
      state.lostConnection = true
    },
    setRecording(state, value){
      state.actualSpot = value
    },
    setTextSpotSpeaker(state, value){
      state.textSpotSpeaker = value
    },
   
    setDataSaveSpot(state, data){
      state.dataSaveSpot = data
    },
    setTextChangedBy(state, role){
      state.roleChangingTxt = role
    },
    setSliderFont(state, value){
      state.sliderSocket = value
    },
    setStatusShowingRecorder(state, value){
      state.closeShowRecording = value
    },
    setStudio(state, value){
      const studio = state.arrStudios.find(studio => studio.name === `Estudio ${value}`) || null
      if (studio) state.idStudio = studio._id
      state.studio = value
    },
    setStudios(state, studios){
      state.studios = studios.count || 3
      state.arrStudios = [...studios.data]
    },
    setBrowserStudio(state, studio) {
      state.studio = studio
    },
    setLogin(state, payload) {
      state.logued = payload
    },

    setActivity(state, activity) {
      state.actualActivity = activity;
    },
    setSpeakerGroup(state, payload) {
      state.currentSpeakerGroup = payload
    },
    setCitation(state, citation) {
      state.currentCitation = citation
    },
    setListSpots(state, payload) {
      state.listSpots = payload
    },
    setListActivities(state, payload) {
      state.listActivities = payload
    },
    updateSpotStatus(state, payload) {
      for (let index = 0; index < state.listSpots.length; index++) {
        const spot = state.listSpots[index];
        if(spot.spotCode == payload.spot.spotCode && spot.index == payload.spot.index) {
          state.listSpots[index].recordingStatus = payload.status
        }
      }
    },
    setTokenData(state,data){
      state.token = data.token;
      state.refreshToken = data.refreshToken;
      state.tokenExpiration = Date.now()+ parseInt(data.tokenExp);
      state.refreshTokenExpiration = Date.now()+ parseInt(data.refreshTokenExp);
      localStorage.setItem("token", data.token)
    },
    setSlaveRemote(state, data) {
      state.currentSlaveRemote = data
      state.requestSlaveRemote = true
      state.currentUser = data.user.name
    },
    responseAccessRemote(state, payload) {
      state.accessRemote = payload
    },
    setUsername(state, payload) {
      localStorage.setItem("currentUser", payload.userName)
      state.currentUser = payload.userName
    },
    setUpdateSpotList(state, payload) {
      state.updateSpotList = payload
    }
  },

  actions: {

    async doLogin({commit, state,dispatch}) {
      // if(COMPROBACIÓN DE SI ES APP O BROWSER)
        await dispatch("loginApp")
    // }else{
    //   await dispatch("loginBrowser")
    // }
    },

    async loginApp({commit, state,dispatch}){
      try {
        let response;
        response = await Vue.prototype.$axios.post(`api/v1/auth/appLogin`,
          {appToken: ""+state.tokenDefault+"" }
        );
          let result = response.data;
          commit('setTokenData', result.data)
          return result.data.token
      } catch (err) {
        // console.log(err);
        // commit("changeAlertModal", err.response.data.result[0]);
      }
    },

    async loginBrowser({commit, state,dispatch},data){
      try {
        // FALTA LA PARTE DE BACK DE AUTENTICAZION MEDIANTE USER Y CONTRASEÑA 
        let response;
        response = await Vue.prototype.$axios.post(`api/v1/auth/login`,
          {email: ""+data.email+"",password: ""+data.password+"" }
        );
          let result = response.data;
          commit('setTokenData', result.data)
          commit('setUsername', result.data)
          return result
      } catch (err) {
        console.log(err);
        return err.response
        // commit("changeAlertModal", err.response.data.result[0]);
      }
    },

    async checkToken({commit, state,dispatch},config) {
      if (config.baseURL === `${process.env.VUE_APP_URL_API}:${state.port}` && (!config.url?.includes('appLogin')  &&  !config.url?.includes('refreshToken') && !config.url?.includes('login'))) {
        if(process.env.VUE_APP_MODE == "electron") {

          if (state.token == "") {
            await dispatch("doLogin");
          } else if (state.refreshToken && state.refreshTokenExpiration < (Date.now() + 30000)) {
            await dispatch("doLogin");
          } else if (state.tokenExpiration < (Date.now() + 30000)) {
            await dispatch("refreshToken");
          }
          return state.token;
        }
        else {
          if (state.tokenExpiration < (Date.now() + 30000)) {
            await dispatch("refreshToken");
          }
          return state.token;
        }
      }
      return null;
    },

    async refreshToken({ commit, state }) {
      try {
        const response = await Vue.prototype.$axios.post('api/v1/auth/refreshToken', {
          refreshToken: state.refreshToken
        });
        const result = response.data;
        commit('setTokenData', result.data);
        return result.data.token;
      } catch (err) {
        console.log(err);
        commit("changeAlertModal", err.response.data.result[0]);
      }
    },
    
    manageError({commit, state}, err){
      commit('setError', err)
      const clearErr = () => { commit('setError', '') }
      const interval = setInterval(() => { clearErr() }, 6000)
      if(err) commit('setIntervarl', interval)
      else clearInterval(state.interval)
    },
    manageSuccess({commit, state}, msg){
      commit('setSuccess', msg)
      const clearSucc = () => { commit('setSuccess', '') }
      const interval = setInterval(() => { clearSucc() }, 6000)
      if(msg) commit('setIntervarl', interval)
      else clearInterval(state.interval)
    },

    //estudios

    async getStudios({ commit }){
      try {
        let response = await Vue.prototype.$axios.get(`/api/v1/studios`)
        let result = response.data
        commit("setStudios", result)
        return result
      }catch (err){
          console.log("err: ",err)
      }
    },
    emmitRoleUser({state}){
      console.log(state.role, state.studio)
      socket.emit('userole', {toNamespace:"ECI_ESTUDIO", toRoom:`estudio${state.studio}`, data: state.role});
    },
    socketConnect({commit, dispatch},value){
      commit("setDialogSocketConnection", !value);
      if (value === false){
        setTimeout(() => {
          if(this.state.allowedCitations === false) {
            commit("emptyUsers");
            commit("setAccesCitations", value);
            commit("setSliderFont", 24);
          }
        }, 3000);
      }
      else{
        dispatch("emmitRoleUser");
        commit("setAccesCitations", value)
      }
    },

    connectTechnician({commit}){
      commit("setConnectedToTechnician");
    },

    usersConnected({commit}, users){
      commit("allUsersConnected", users);
    },

    removeUser({ commit },value){
      commit("removeConnectedUser", value);
    },

    getSliderFont({ commit },value){
      commit("setSliderFont", value);
    },

    getStatusShowingRecorder({ commit },value){
      commit("setStatusShowingRecorder", value);
    },

    getTextToSpeaker({ commit, state },text){
      if (text !== state.textSpotSpeaker) commit("setTextSpotSpeaker", text);
    },

    getLangIdSpot({ commit, state },id){
      const data = {...state.dataSaveSpot, criLang:id}
      commit("setDataSaveSpot", data);
    },

    getSaveDataSpot({ commit },data){
      commit("setDataSaveSpot", data);
    },

    getTextChangedBy({ commit },role){
      commit("setTextChangedBy", role);
    },

    receiveCreatives({ commit },data){
      commit("setCreatives", data);
    },
    createDiv({ commit }, text){
      if (text.includes('<div>')){
        if(text.slice(0, 5) !== '<div>') `<div>${text.replace("<div>", "</div><div>") }`
      }else{
        text = `<div>${text}</div>`
      }

      commit("setTextSpotSpeaker",text);

    },

    accesCitations({ commit, dispatch },value){
      if (typeof value.acces === 'boolean' &&  value.recording != null){
        commit("setAccesCitations", value.acces);
        dispatch("createDiv", value.recording.texts.text)
        // commit("setTextSpotSpeaker", value.recording.texts[0].text);
        commit("setRecording", value.recording);
      }else if(typeof value.acces === 'boolean' &&  value.recording === null){
        commit("setAccesCitations", value.acces);
        commit("setSliderFont", 24);
      }
    },


    async sendTextSpot({ commit, state }, data){
      try {
        const { code, criLang, taskId, mediaId, text } = data

        let dataSend = {code, criLang, taskId, mediaId, text}
        if (data.mediaType === "MEGAFONIA" || data.mediaType === "RADIO") dataSend = {...dataSend, criLang}
        
        const response = await Vue.prototype.$axios.put(`/api/v1/spots/text`,dataSend)
        const result = response.data || ''

        return response
      }catch (err){
          console.log("err: ",err)
      }

    },


    async receiveCitations({ commit, state }, dates){
      try {
        const response = await Vue.prototype.$axios.get(`/api/v1/studios/${state.idStudio}/tasks?type=citation&sort=initDate&initDate[gte]=${dates.initDate}&endDate[lte]=${dates.endDate}`)
        const result = response.data || ''
        const citations = (result && result.data && result.data.length > 0) ? result.data : []
        commit("setCitations", citations);
        commit("setSpeakersGroup", citations);
        return result
      }catch (err){
          console.log("err: ",err)
      }

    },

    async selectActivity({ commit },activiy){
      commit("setRecordings", activiy.recordings);
      commit("setActiviyId", activiy.activityId._id);
      commit("setActiviyName", activiy.activityId.description);
      return true
    },

    async getSpotsByCode({ commit },idsSpots){
      try {
        const data = {spot_code:[...idsSpots]};
        const response = await Vue.prototype.$axios.post(`/api/v1/spots/code?project_id=5137`,data)
        const result = response.data || ''
        const spots = (result) ? result.response : []
        commit("setSpots", spots);
        return spots
      }catch (err){
          console.log("err: ",err)
      }
    },
    async fetchSpotsByCode({commit}, data) {
      try {
          let response = await Vue.prototype.$axios.post(`/api/v1/spots/codesByLang`, {spot_code: data})

          commit("setSpots", response.data.data)

          return response
      } catch (err) {
          return err
      }

  },
  },
  modules: {
    spots
  }
})
